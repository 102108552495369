.nav-header-bg{
	/*position: absolute;*/
	background: transparent;
	height: 56px;
	/*margin-top: 20px;*/
	width: 1200px;
	margin: 0 auto;
  position: relative;
  z-index: 3;
	& .nav-header{
		font-size: 0;
		overflow: hidden;
		& img{
			float: left;
			margin-top: 20px;
		}
		& .links{
			float: right;
			margin-top: 20px;

			& a{
				display: block;
				margin-left: 45px;
				font-size: 16px;
				line-height: 36px;
				float: left;
			}
			& a:hover{
				border-bottom: 2px solid #15a4ff;
			}
		}
	}

}
