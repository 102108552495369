.company{
				background: url(/assets/img/home/home_bottom.jpg) no-repeat;
				height: 270px;
				text-align: center;
				font-size: 0;
				background-size: cover;
				min-width: 1200px;
				& img{
					margin-top: 80px;
					margin-bottom: 18px;

				}
				& div.h1{
					color: #fff;
					line-height: 34px;
          font-size: 34px;
          font-weight: normal;
				}
		}


		 .company-info-bg{
			width: 100%;
			height: 180px;
			background: #fff;
			min-width: 1200px;
			& .company-info{
				width: 1200px;
				margin: 0 auto;
				margin-top: 35px;
				color: #333;
				& .left{
					float: left;
					& a.contact-us{
						display: block;
						font-size: 14px;
						line-height: 18px;
						font-weight: bold;
						margin-bottom: 22px;
					}
					& .email{
						font-size: 14px;
						line-height: 26px;
						margin-bottom: 3px;
					}
					& .company-name{
						font-size: 0;
						& img{
							vertical-align: middle;
							float: left;
						}
						& p{
							float: left;
							font-size: 14px;
							line-height: 14px;
							vertical-align: middle;
							margin-top: 3px;
						}
					}
					& .number{
						font-size: 14px;
						line-height: 34px;
					}

				}
				& .right{
					float: right;
					text-align: center;
					font-size: 0;
					margin-top: -15px;
					& img{}
					& div.p{
						font-size: 14px;
						line-height: 14px;
						font-weight: bold;
            text-align: center;
					}
				}
			}
		}
